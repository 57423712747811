<template>

  <footer class="footer">
    <div class="footer__container">
      <router-link to="/datenschutz" class="footer__container-link">Datenschutz</router-link>
      <router-link to="/impressum" class="footer__container-link">Impressum</router-link>
    </div>
  </footer>

</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped lang="sass">
.footer

  &__container
    max-width: 640px
    margin: 0 auto
    padding: 10px 0
    display: flex
    align-items: center
    justify-content: center
    color: #ccc

    &-link
      color: inherit
      text-decoration: none
      margin: 0 10px


</style>
